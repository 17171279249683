@import "../../styles/_variables";

ul.actions {
  position: absolute;
  left: -30px;
  top: 30px;
  list-style: none;

  li {
    width: 30px;
    height: 30px;
    cursor: pointer;

    &.colorize {
      background-image: url("../../static/images/color.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      filter: gray;
      -webkit-filter: grayscale(1);
      &:hover {
        filter: none;
        -webkit-filter: grayscale(0);
      }
    }
  }
}

@media (max-width: 1024px) {
  ul.actions {
    right: 30px;
    left: initial;
    top: 0;

    li {
      width: 60px;
      height: 60px;
    }
  }
}

@media print {
  ul.actions {
    display: none;
  }
}
