import React from 'react';
import Technology from './technology/technology';
import * as styles from '../main.scss';

const Job = (props) => {
  const {
    company,
    link,
    when,
    title,
    stack,
    description,
  } = props;

  return (
    <div className={styles.infoBlock}>
      <h3><a href={link} target="_blank" className={styles.infoBlockLink}>{company}</a> - <span>{title}</span></h3>
      <div className={styles.infoBlockDate}>{when}</div>
      <div className={styles.infoBlockArea}>
        <span className={styles.infoBlockSubtitle}>Stack: </span>
        {
          stack.map(tech => <Technology key={tech} name={tech}/>)
        }
      </div>
      <div>
        <span className="info-block__subtitle">Description: </span>{description}
      </div>
    </div>
  )
}


export default Job;
