import React from 'react';

import * as styles from './styles.scss'
import palettes from 'nice-color-palettes';

const Actions = () => {
  return (
    <ul className={styles.actions}>
      <li
        className={styles.colorize}
        title="Random color scheme"
        onClick={applyRandomColour}
      />
    </ul>
  )
}


const applyRandomColour = () => {
  const palette = palettes[Math.floor(palettes.length * Math.random())];
  const colorSchemas = palette.map(p => {
    const rgb = hexToRgb(p);
    return {
      hex: p,
      brightness: brightness(rgb.r, rgb.g, rgb.b),
    }
  });
  colorSchemas.sort((a, b) => a.brightness - b.brightness);

  document.documentElement.style.setProperty('--color-back-main', colorSchemas[0].hex);
  document.documentElement.style.setProperty('--color-back-alt', colorSchemas[1].hex);
  document.documentElement.style.setProperty('--color-font-header', colorSchemas[2].hex);
  document.documentElement.style.setProperty('--color-font-alt-header', colorSchemas[3].hex);
  document.documentElement.style.setProperty('--color-font-main', colorSchemas[4].hex);
}


const brightness = (r, g, b) => {
  return (299 * r + 587 * g + 114 * b) / 1000;
}

const hexToRgb = (hex) => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export default Actions;
