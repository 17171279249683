const sdl = {
    company: 'RWS',
    link: 'http://rws.com/',
    location: 'Amsterdam, Netherlands',
    when: 'Jul 2019 - present',
    title: 'UI Team Lead',
    stack: [ 'React', 'Redux'],
    description: `Senior front-end developer with Team Lead responsibility (with approx distribution 70% as a developer and 30% as lead).
    Working in react/redux/sagas/react-query environments. Close cross-team collaboration, team temperature checking, requirement gathering.`
};

const ansarada = {
    company: 'Ansarada Vietnam',
    link: 'http://ansarada.com/',
    location: 'Ho Chi Minh, Viet Nam',
    when: 'Mar 2017 - Jun 2019',
    title: 'Senior Frontend Developer',
    stack: [ 'React', 'Redux', 'VanillaJs', '.Net', 'Unit tests', 'E2e tests'],
    description: `Convert legacy application (.net asp/MVC) to react platform.
    Analyze and tune the current flow of the application. Working on an improvement of the deployment process.
    Support and contribute react component library.
    Leading the features of the project, analyzing customer experience, building roadmaps and making technical decisions.
    Close collaboration with Pm and designers.`
};

const luxoft = {
    company: 'Luxoft Kiev',
    link: 'https://www.luxoft.com/',
    when: 'Aug 2016 – Mar 2017',
    title: 'Senior Frontend Developer',
    stack: [ 'Angular 1.*', 'NodeJs', 'VanillaJs', 'BEM', 'Unit tests', 'Visual tests'],
    description: `Worked on image.net project. Migration legacy application to the new angular platform.  Helped Pm and Lead to build a roadmap, prioritizing features and improving test coverage.  Close integration with back-end and QA teams, support of node js services. Implementing new UI, Gemini tests, support and organize BEM structure.`
};

const infopulse = {
    company: 'Infopulse Kiev',
    link: 'https://www.infopulse.com/',
    when: 'Mar 2015 – Aug 2016',
    title: 'Senior Frontend Developer / Team Lead',
    stack: [ 'Angular 1.*', 'Bootstrap', 'VanillaJs', 'BEM', 'Unit tests'],
    description: `Worked on internet banking project. Creating new UI using wireframe and design. Supporting common component library that was shared across projects in a distributed environment. Creating and leading FE dev team. Analyzing business requirements, designing API and creating technical documentation. Close collaboration with the back-end teams, BA, designers, and testers.`
};

const yandex = {
    company: 'Yandex Kiev',
    link: 'https://www.yandex.com/',
    when: 'Nov 2013 – Mar 2015',
    title: 'Middle Frontend Developer',
    stack: [ 'VanillaJs', 'BEM', 'Nodejs', 'Unit tests', 'Visual tests'],
    description: `Worked on the search engine result page. I was doing a lot of UI experiments in close cooperation with Pm. I was a lead developer on people search project. Contribution into local UI library - Lego.  Close collaboration with Pm / back-end / other teams`
};

const mcdean = {
    company: 'McDean',
    link: 'https://www.mcdean.com/',
    when: 'Apr 2009 – Nov 2013',
    title: 'Middle / Senior full stack developer',
    stack: [ 'Asp.Net', 'MsSql', 'jquery', 'PHP' ],
    description: `Worked on the search engine result page. I was doing a lot of UI experiments in close cooperation with Pm. I was a lead developer on people search project. Contribution into local UI library - Lego.  Close collaboration with Pm / back-end / other teams`
};

const nma = {
    company: 'NewMediaAgency',
    when: 'Dec 2006 – Apl 2006',
    title: 'Junior / Middle full stack developer',
    stack: [ 'PHP', 'Ruby', 'Action Script' ],
    description: `Worked on reach multimedia portals. Full circle application:  database, application architecture, API design, and UI. Also, I’ve supported the company  ActionScript framework and Rails framework. I was supporting multiple projects at the same time in close collaboration with the customers.`
};

const jobList = [
    sdl, ansarada, luxoft, infopulse, yandex, mcdean, nma,
];

export default jobList;

