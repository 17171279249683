import React from 'react';
import * as styles from './styles.scss';

const Row = (props) => {
  const { left, right } = props;

  return (
    <div className={styles.row}>
      <div className={styles.left}>{left}</div>
      <div className={styles.right}>{right}</div>
    </div>
  )
}

export default Row;
