@import '../../styles/_variables';

.row {
  display: flex;
  flex-direction: row;
}

.left {
  width: 250px;
  flex-shrink: 0;
  background: $color-back-alt;
  text-align: right;
  padding-right: 20px;

}

.right {
  flex-grow: 1;
  padding: 10px 20px 0 20px;
}


@media (max-width: 1024px) {
  .left {
    flex-grow: 1;
    width: 100%;
    text-align: left;
    padding-left: 20px;
  }

  .row {
    flex-direction: column;
  }
}

