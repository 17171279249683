import React from 'react';
import { render } from 'react-dom';
import App from './components/app.jsx';
import * as styles from './styles/_default.scss';


const documentRootElement = document.getElementById('app-root');

render(<App/>, documentRootElement);


