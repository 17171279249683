export const personalInfo = {
    name: 'Sergii Synevych',
    role: 'Developer / Team Lead',
    location: 'Netherlands, Amsterdam',
    age: (() => {
        const diff = new Date().getTime() - (new Date(1984, 6, 12)).getTime();
        return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    })(),
    email: 'sinevich.sergey@gmail.com'
}

export const summaryInfo = [
    'Highly experienced person in software development;',
    'More than 10 years of front-end experience (React, Vanilla js, Angular);',
    'About 6 years of back-end experience (.Net, PHP, Ruby);',
    'Skilled in various tests techniques (unit, e2e, visual appearance);',
    'Project Management, Business Analytics, Designers, and QA are my favorite - only together can we build a solid and awesome product.',

]

export const educationInfo = {
    place: 'Odessa National Polytechnic University',
    years: '2001 - 2006',
    degree: 'Master\'s degree in Information Technology and Information Control System',
    diploma: 'Development of the Remote Education system'
}