@import 'styles/_variables';

.appContent {
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
}

.summaryList {
  margin: 0;
  padding: 0 10px;
  list-style: none;

  li {
    &::before {
      content: "\2022";
      color: $color-font-header;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
    margin: 4px 0;
  }
}

.role {
  font-size: 32px;
  color: $color-font-main;
  text-align: center;
}

.avatar {
  display: inline-block;
  background-image: url("./static/images/avatar.jpg");
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 130px;
  height: 130px;
}

@media print {
  .avatar {
    display: none;
  }
}

.infoBlock {
  padding-bottom: 20px;
  display: inline-block;
  margin-right: 30px;
}


.infoBlockArea {
  margin: 8px 0;
}

.infoBlockDate {
  font-style: italic;
}

.infoBlockSubtitle {
  font-weight: bold;
  margin-right: 8px;
}

.infoBlockLink {
  color: $color-font-header;
}

