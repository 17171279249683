import React from 'react';
import jobList from './data/job-list';
import { personalInfo, summaryInfo, educationInfo } from './data/personal';
import Row from './row/row';
import Actions from './actions/actions';

import * as styles from '../main.scss'

import Job from './job';

export default App = () => {
  return (
    <div className={styles.appContent}>
      <Actions />
      <Row
        left={<div className={styles.avatar}/>}
        right={
          <>
            <h1>{personalInfo.name}</h1>
            <div className={styles.role}>{personalInfo.role}</div>
          </>
        }
      />

      <Row
        left={<h2>Personal info</h2>}
        right={
          <>
            <div className={styles.infoBlock}>
              <strong>Location: </strong><div>{personalInfo.location}</div>
            </div>
            <div className={styles.infoBlock}>
              <strong>Age: </strong><div>{personalInfo.age}</div>
            </div>
            <div className={styles.infoBlock}>
              <strong>Email: </strong><div>{personalInfo.email}</div>
            </div>
          </>
        }
      />

      <Row
        left={<h2>Summary</h2>}
        right={
          <ul className={styles.summaryList}>
            {summaryInfo.map((summary, i) => <li key={i}>{summary}</li>)}
          </ul>
        }
      />
      <Row
        left={
          <h2>Experience</h2>
        }
        right={
          jobList.map(job => <Job key={job.company} {...job} />)
        }
      />

      <Row
        left={<h2>Education</h2>}
        right={
          <div className={styles.infoBlock}>
            <h3>{educationInfo.place}</h3>
            <div className={styles.infoBlockDate}>{educationInfo.years}</div>
            <div><span className={styles.infoBlockSubtitle}>Description:</span>{educationInfo.degree}</div>
            <div>
              <span className={styles.infoBlockSubtitle}>Diploma Topic:</span>{educationInfo.diploma}
            </div>
          </div>
        }
      />

      <Row
        left={<h2>Other</h2>}
        right={
          <div className={styles.infoBlock}>
            I was part of the <a href="http://frontend-science.com/" target="_blank">Frontend science</a> team as the mentor. Also, I‘ve created and led the classes about front-end testing.
          </div>
        }
      />
    </div>
  )
}
