import React from 'react';

import * as styles from './styles.scss';

const Technology = (props) => {
    const { name } = {...props };

    return (
        <span className={styles.technologyName}>{name}</span>
    );
}

export default Technology;
